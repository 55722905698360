<template>
	<div class="container">
	 	 <div class="modal fade visitModal" :class="[{'show' : $parent.showPopup != ''}]">
            <div class="modal-dialog modal-dialog-centered max-600 w-100">
                <div class="modal-content">
                    <div class="modal-header pb-0 justify-content-end border-0">
                        <a href="javascript:void(0)" class="close"
                            @click="$parent.showPopup = '';loginform = {email: '',password:''}">
                            <vue-feather type="x"></vue-feather>
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="loginPortfolio h-100">
                            <h3 class="text-center mb-5">Log into Portfolios</h3>
                            <Form class="login was-validated" @submit="submit">
                                <!--Click submit add class "was-validated"-->
                                <div class="form-group">
                                    <label>Email Address</label>
                                    <Field type="text" class="form-control" name="Email" rules="required|email"
                                        v-model="loginform.email" />
                                    <ErrorMessage as="paragraph" name="Email" class="text-danger mb-0" />
                                </div>
                                <div class="form-group pb-2">
                                    <label>Password</label>
                                    <Field type="password" class="form-control" name="Password" rules="required"
                                        v-model="loginform.password" />
                                    <ErrorMessage as="paragraph" name="Password" class="text-danger mb-0" />
                                </div>
                                <div class="form-group">
                                    <button class="grd_btn w-100">LOG IN</button>
                                </div>
                            </Form>
                            <div class="or text-center mb-4 d-none">
                                <p class="mb-0 d-inline-flex">OR</p>
                            </div>
                            <div class="register text-center pb-4">
                               Don’t have an account?
                                <router-link to="/register" class="green underline"
                                  >Sign Up Here</router-link
                                >
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
  import { useMainStore } from "@/store";
  import moment from 'moment'
  import { Form, Field, ErrorMessage } from "vee-validate";
export default {
   setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
          imageLink : process.env.VUE_APP_API_BASE_URL,
          loginform: {
                email: "",
                password: "",
            },
        }
    }, 
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    methods:{
    	submit() {
            let form = {
                'email': this.loginform.email,
                'password': this.loginform.password,
                'from': 'profile'
            }
            this.store.login(form, true, this);
        },
    }
}
</script>
<style scoped>
.modal.fade.show {
    opacity: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
}
</style>
