<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement bg-white innerPage-banner marketTrend pb-4">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>Market Trend</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item">
                                            <router-link class="green" to="/">Home</router-link>
                                        </li>
                                        <li class="breadcrumb-item active" aria-current="page">Market Trend</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- <div class="rightSearch hideMobile">
                                <form class="searchBar">
                                    <div class="form-group position-relative inputIcon mb-0">
                                        <input type="text" class="form-control" placeholder="EURUSD" name="" /> <a class="searchButton grd_btn" href="javascript:void(0)">Chart Patterns</a>
                                    </div>
                                </form>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white trendMarket">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- <div class="d-flex justify-content-between border-bottom trade mb-3">
                            <ul class="toplinks pb-3 d-flex align-items-center mb-0">
                                <li class="ps-0"><a class="medium" href="javascript:void(0)"
                                        @click="currenTab='Trending'"
                                        :class="[{active: currenTab=='Trending'}]">Trending</a></li>
                                <li class="ps-3"><a class="medium" href="javascript:void(0)" @click="currenTab='Cross'" :class="[{active: currenTab=='Cross'}]"> Cross Pairs</a></li>
                            </ul>
                            <ul class="toplinks pb-2 d-flex align-items-center mb-0 topList hideMobile">
                                <li class="ps-0"><a class="active" href="javascript:void(0)"> All</a></li>
                                <li><a href="javascript:void(0)">Forex</a></li>
                                <li><a href="javascript:void(0)">Crypto</a></li>
                                <li><a href="javascript:void(0)">Commodities</a></li>
                                <li><a href="javascript:void(0)">Indices</a></li>
                            </ul>
                        </div> -->
                        <div class="table-responsive economic mkt border">
                            <table class="table table-sm m-0 bg-white"><!-- fixed-layout -->
                                <thead>
                                    <tr class="border-top-0">
                                        <th width="300">Symbol</th>
                                        <th width="250">
                                            <span class="d-flex align-items-center viewYears"> <span>D</span>
                                                <span>W</span> <span>M</span> <span>Y</span> </span>
                                        </th>
                                        <!--  <th>Chart</th> -->
                                       <!--  <th width="">Support-Resistance</th> -->
                                        <th>News</th>
                                        <th class="text-end pe-3">Ideas</th>
                                    </tr>
                                </thead>

                                 <tbody v-if="!store.loading && Object.keys(store.trendingisinsList).length && Object.keys(instruments).length">
                                    <tr v-for="(isins, key) in store.trendingisinsList" :key="key">
                                        <td colspan="6" class="p-0 border-0">
                                            <table class="table innerTable subTable">
                                                <tr>
                                                    <table class="table innerTable subTable">
                                                        <tbody>
                                                            <tr>
                                                                <td colspan="4" class="p-0 border-0">
                                                                    <table class="table fixed-layout qrTable">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td width="300" class="pe-4">
                                                                                   <span class="d-flex align-items-center mb-2">
                                                                                       <div class="dualIcon">
                                                                                            <a class="d-inline-flex mx-2" href="javascript:void(0)" v-if="key">
                                                                                              <img :src=" 'assets/images/crypto/' + key.slice(0, 3).toLowerCase() + '.webp' " alt="" width="23" height="23"  @error="handleImgErr($event)" v-if="!key.includes('-') && isImage(key.slice(0, 3).toLowerCase())" />
                                                                                              <img :src=" 'assets/images/crypto/' + key.split('-')[0].toLowerCase() + '.webp' " alt="" width="23" height="23"  @error="handleImgErr($event)" v-else-if="key.includes('-') && isImage(key.split('-')[0].toLowerCase())" />
                                                                                              <span class="fsymbol" v-else>{{key.slice(0,1)}}</span>
                                                                                            </a>



                                                                                             <a class="d-inline-flex mx-2" href="javascript:void(0)" v-if="key">
                                                                                              <img :src=" 'assets/images/crypto/' + key.slice(3, 7).toLowerCase() + '.webp' " alt="" width="23" height="23" v-if="!key.includes('-') && isImage(key.slice(3, 7).toLowerCase())" @error="handleImgErr($event)" />
                                                                                               <img :src=" 'assets/images/crypto/' + key.split('-')[1].toLowerCase() + '.webp' " alt="" width="23" height="23"  @error="handleImgErr($event)" v-else-if="key.includes('-') && isImage(key.split('-')[1].toLowerCase())" />
                                                                                              <span class="fsymbol" v-else>{{key.slice(3,7).slice(0,1)}}</span>
                                                                                            </a>
                                                                                        </div>
                                                                                             <span
                                                                                            class="medium ctr ps-2">{{key}}
                                                                                            <span
                                                                                                class="trendPrice" v-if="instruments[key]">({{instruments[key].B}})</span>
                                                                                            </span>
                                                                                    </span>
                                                                                    <!-- <span class="d-flex align-items-center justify-content-between"> <span class="valueAdd green">{{isins.O}}</span> <span class="valueAdd">{{isins.other.closepx}}</span> </span> -->
                                                                                   <span class="trendsChart" v-if="instruments[key]">
                                                                                        <span class="progress custom overflow-visible">
                                                                                            <div class="movementBarContainer">
                                                                                                <span class="leftBox" :style="movementBar(instruments[key]).left"></span>
                                                                                                <span class="rightBox" :style="movementBar(instruments[key]).right"></span>
                                                                                                <span class="currentPrice"
                                                                                                    :style="movementBar(instruments[key]).current"></span>
                                                                                            </div>
                                                                                        </span>
                                                                                        <span class="lhMezor"> <span class="left position-absolute f-10">L</span>
                                                                                            <span class="f-10 right position-absolute">H</span> </span>
                                                                                    </span>
                                                                                    <span class="d-flex align-items-center justify-content-between px-3 pt-2" v-if="instruments[key]"> <span class="f-9">{{instruments[key].L}}</span> <span class="f-9">{{instruments[key].H}}</span> </span>
                                                                                </td>
                                                                                <td width="250">
                                                                                    <span class="d-flex align-items-center viewYearsTD" v-if="instruments[key]">
                                                                                        <span class="f-11 medium">{{COMMON.formatPrice(instruments[key].CB)}}%</span> <span
                                                                                            class="f-11 medium">{{getTrendingData(instruments[key].other.mn,
                                                                                            "weekly",instruments[key].B)}}%</span>
                                                                                        <span class="f-11 medium">{{getTrendingData(instruments[key].other.mn,
                                                                                        "monthly",instruments[key].B)}}%</span>
                                                                                        <span class="f-11 medium">{{getTrendingData(instruments[key].other.mn,
                                                                                        "yearly",instruments[key].B)}}%</span>
                                                                                    </span>
                                                                                </td>
                                                                                <!-- <td><img class="fluidImg" src="assets/images/chart/relatedPair.svg" alt="Chart" title="Chart" /></td> -->
                                                                                <!-- <td><img src="assets/images/chart/weekdays.png" class="fluidImg" alt="Chart" title="Week" /></td> -->
                                                                                 <td class="f-12 line-4"
                                                                                    v-if=" store.trendingisinsList[key] && store.trendingisinsList[key].news.length ">
                                                                                    <span v-if="store.trendingisinsList[key].news[0].metadata && store.trendingisinsList[key].news[0].metadata.header"  @click="()=> goToDetailPage(store.trendingisinsList[key].news[0])" :id="'news'+store.trendingisinsList[key].news[0]._id">{{store.trendingisinsList[key].news[0].metadata.header}}</span>
                                                                                    <span v-else @click="()=> goToDetailPage(store.trendingisinsList[key].news[0])" :id="'news'+store.trendingisinsList[key].news[0]._id">{{store.trendingisinsList[key].news[0].title}}</span>
                                                                                </td>
                                                                                 <td class="f-12 line-4" v-else>N/A</td>
                                                                                 <td>
                                                                                    <div class="d-flex align-items-center justify-content-end">
                                                                                        <div class="perparing" v-if=" store.trendingisinsList[key] && store.trendingisinsList[key].trade.length ">
                                                                                            <span class="mb-1 d-block f-12">{{store.trendingisinsList[key].trade[0].title}}</span>
                                                                                            <!-- <div class="smallbtn intable d-flex align-items-center">
                                                                                                <a href="javascript:void(0)" class="sharemkt me-1"><img src="assets/images/bull.svg" /></a>
                                                                                                <a href="javascript:void(0)" class="tagged">Long</a> <a href="javascript:void(0)" class="tagged">Premium</a>
                                                                                            </div> -->
                                                                                        </div>
                                                                                        <div class="perparing" v-else>N/A</div>
                                                                                        <div class="d-flex align-items-center">
                                                                                            <a href="javascript:void(0)" class="sharemkt large me-4"><img src="assets/images/bull-bear.svg" /></a>
                                                                                            <a href="javascript:void(0)" :class="details == key ? 'added' : 'add'" class="tradeBtn" @click="(details != key) ? getTrendingSymbolEvents(key) : details = -1">
                                                                                                <vue-feather :type="details == key ? 'minus' : 'plus'" size="16px"> </vue-feather>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                

                                                                            </tr>

                                                                            <!-- Detail Div  -->
                                                                            <tr class="marketShowTR" :class="details == key ? 'show':''" v-if="details == key">
                                                                                <td colspan="4" class="p-0 border-0">
                                                                                    <table class="table fixed-layout qrTable">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td width="280">
                                                                                                    <!-- Weekly -->
                                                                                                    <span class="d-flex align-items-center pb-4 flex-wrap" v-if="Object.keys(store.trendingisinsList).length &&  Object.keys(instruments).length && instruments[key]"> 
                                                                                                        <span class="valueChart w-100">
                                                                                                            <span class="progress custom overflow-visible justify-content-center">
                                                                                                                <div class="progress-bar overflow-visible topDym" :style="weekprogressChart.progressBar" >
                                                                                                                    <span class="triangle d-flex align-items-center" :style="weekprogressChart.icon" :class="weekprogressChart.class">
                                                                                                                        <i class="fa fa-caret-down" aria-hidden="true"></i> 
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <span class="d-flex align-items-center justify-content-between position-absolute gdr fix">
                                                                                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingisinsList[key].weekly) == 'object' && JSON.parse(store.trendingisinsList[key].weekly).low">
                                                                                                                        <span class="iconColor">Low </span>
                                                                                                                        <b v-if="isMonday() && instruments[key].L">{{parseFloat(instruments[key].L).toFixed(5)}}</b>
                                                                                                                        <b v-else-if="instruments[key].L && (parseFloat(instruments[key].L) < parseFloat(JSON.parse(store.trendingisinsList[key].weekly).low))">{{parseFloat(instruments[key].L).toFixed(5)}}</b>
                                                                                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingisinsList[key].weekly).low).toFixed(5)}}</b>
                                                                                                                    </span>
                                                                                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingisinsList[key].weekly) == 'object' && JSON.parse(store.trendingisinsList[key].weekly).high">
                                                                                                                        <span class="iconColor">High </span>
                                                                                                                        <b v-if="isMonday() && instruments[key].H">{{parseFloat(instruments[key].H).toFixed(5)}}</b>
                                                                                                                        <b v-else-if="instruments[key].H && (parseFloat(instruments[key].H) > parseFloat(JSON.parse(store.trendingisinsList[key].weekly).high))">{{parseFloat(instruments[key].H).toFixed(5)}}</b>
                                                                                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingisinsList[key].weekly).high).toFixed(5)}}</b>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <span class="text-center w-100 mb-2 timetitle ">Weekly</span>
                                                                                                    </span>
                                                                                                    <!-- Monthly -->
                                                                                                    <span class="d-flex align-items-center flex-wrap pb-4" v-if="Object.keys(store.trendingisinsList).length &&  Object.keys(instruments).length && instruments[key]"> 
                                                                                                        <span class="valueChart w-100">
                                                                                                            <span class="progress custom overflow-visible justify-content-center">
                                                                                                                <div class="progress-bar overflow-visible topDym" :style="monthprogressChart.progressBar" >
                                                                                                                    <span class="triangle d-flex align-items-center" :style="monthprogressChart.icon" :class="monthprogressChart.class">
                                                                                                                        <i class="fa fa-caret-down" aria-hidden="true"></i> 
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <span class="d-flex align-items-center justify-content-between position-absolute gdr fix">
                                                                                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingisinsList[key].monthly) == 'object' && JSON.parse(store.trendingisinsList[key].monthly).low">
                                                                                                                        <span class="iconColor">Low </span>
                                                                                                                        <b v-if="isMonday() && instruments[key].L">{{parseFloat(instruments[key].L).toFixed(5)}}</b>
                                                                                                                        <b v-else-if="instruments[key].L && (parseFloat(instruments[key].L) < parseFloat(JSON.parse(store.trendingisinsList[key].monthly).low))">{{parseFloat(instruments[key].L).toFixed(5)}}</b>
                                                                                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingisinsList[key].monthly).low).toFixed(5)}}</b>
                                                                                                                    </span>
                                                                                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingisinsList[key].monthly) == 'object' && JSON.parse(store.trendingisinsList[key].monthly).high">
                                                                                                                        <span class="iconColor">High </span>
                                                                                                                        <b v-if="isMonday() && instruments[key].H">{{parseFloat(instruments[key].H).toFixed(5)}}</b>
                                                                                                                        <b v-else-if="instruments[key].H && (parseFloat(instruments[key].H) > parseFloat(JSON.parse(store.trendingisinsList[key].monthly).high))">{{parseFloat(instruments[key].H).toFixed(5)}}</b>
                                                                                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingisinsList[key].monthly).high).toFixed(5)}}</b>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <span class="text-center w-100 mb-2 timetitle">Monthly</span>
                                                                                                    </span>
                                                                                                    <!-- Yearly -->
                                                                                                    <span class="d-flex align-items-center  flex-wrap" v-if="Object.keys(store.trendingisinsList).length &&  Object.keys(instruments).length && instruments[key]"> 
                                                                                                        <span class="valueChart w-100">
                                                                                                            <span class="progress custom overflow-visible justify-content-center">
                                                                                                                <div class="progress-bar overflow-visible topDym" :style="yearprogressChart.progressBar" >
                                                                                                                    <span class="triangle d-flex align-items-center" :style="yearprogressChart.icon" :class="yearprogressChart.class">
                                                                                                                        <i class="fa fa-caret-down" aria-hidden="true"></i> 
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <span class="d-flex align-items-center justify-content-between position-absolute gdr fix">
                                                                                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingisinsList[key].yearly) == 'object' && JSON.parse(store.trendingisinsList[key].yearly).low">
                                                                                                                        <span class="iconColor">Low </span>
                                                                                                                        
                                                                                                                        <b v-if="instruments[key].L && (parseFloat(instruments[key].L) < parseFloat(JSON.parse(store.trendingisinsList[key].yearly).low))">{{parseFloat(instruments[key].L).toFixed(5)}}</b>
                                                                                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingisinsList[key].yearly).low).toFixed(5)}}</b>
                                                                                                                    </span>
                                                                                                                    <span class="f-12" v-if="typeof JSON.parse(store.trendingisinsList[key].yearly) == 'object' && JSON.parse(store.trendingisinsList[key].yearly).high">
                                                                                                                        <span class="iconColor">High </span>
                                                                                                                        
                                                                                                                        <b v-if="instruments[key].H && (parseFloat(instruments[key].H) > parseFloat(JSON.parse(store.trendingisinsList[key].yearly).high))">{{parseFloat(instruments[key].H).toFixed(5)}}</b>
                                                                                                                        <b v-else>{{parseFloat(JSON.parse(store.trendingisinsList[key].yearly).high).toFixed(5)}}</b>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                         <span class="text-center w-100 mb-2 timetitle">Yearly</span>
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td width="250" v-if="store.trendingSymbolEvents.length > 0">
                                                                                                    <span class="d-flex align-items-center border-bottom pb-2 mb-2" v-for="event,item in store.trendingSymbolEvents.slice(0,3)">
                                                                                                        <img width="20" class="me-2"
                                                                                                        :src="
                                                                                                          'assets/images/country_flag/' +
                                                                                                          event.country.toLowerCase() +
                                                                                                          '.webp'
                                                                                                        " alt="Flag" :title="item.country" />
                                                                                                        <span class="medium f-15 ctr ps-2 f-15">
                                                                                                            {{event.event}}<span class="d-block f-11 regular">{{formatEventDate(event.date)}}</span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <!-- <span class="d-flex align-items-center border-bottom pb-2 mb-2">
                                                                                                        <img src="assets/images/market-trend-flag1.png" alt="" />
                                                                                                        <span class="medium f-15 ctr ps-2 f-15">
                                                                                                            RBA Interest Rate Decision<span class="d-block f-11 regular">July 18, 2021 | 09:00 am</span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <span class="d-flex align-items-center">
                                                                                                        <img src="assets/images/market-trend-flag1.png" alt="" />
                                                                                                        <span class="medium f-15 ctr ps-2 f-15">
                                                                                                            RBA Interest Rate Decision<span class="d-block f-11 regular">July 18, 2021 | 09:00 am</span>
                                                                                                        </span>
                                                                                                    </span> -->
                                                                                                </td>
                                                                                                <td width="250" v-else></td>
                                                                                                <!-- <td><img src="assets/images/emerging1.jpg" alt="Images" class="chartBlock" /></td> -->
                                                                                                <td class="p-0">
                                                                                                    <div class="table-responsive" v-if="Object.keys(store.trendingisinsList).length &&  Object.keys(instruments).length && instruments[key]">
                                                                                                        <table class="table resData">
                                                                                                            <tr v-if="store.trendingisinsList[key].yesterday">
                                                                                                                <th class="green">D</th>
                                                                                                                <td v-for="list,index in pivotPoints"><span :class="index > 5 ? 'green' : 'red'">{{list.toUpperCase()}}</span>{{getPoints(store.trendingisinsList[key].yesterday,list,key)}}</td>
                                                                                                                
                                                                                                            </tr>
                                                                                                            <tr v-if="store.trendingisinsList[key].lastweek">
                                                                                                                <th class="green">W</th>
                                                                                                                <td v-for="list,index in pivotPoints"><span :class="index > 5 ? 'green' : 'red'">{{list.toUpperCase()}}</span>{{getPoints(store.trendingisinsList[key].lastweek,list,key)}}</td>

                                                                                                            </tr>
                                                                                                            <tr v-if="store.trendingisinsList[key].lastmonth">
                                                                                                                <th class="green">M</th>
                                                                                                                <td v-for="list,index in pivotPoints"><span :class="index > 5 ? 'green' : 'red'">{{list.toUpperCase()}}</span>{{getPoints(store.trendingisinsList[key].lastmonth,list,key)}}</td>
                                                                                                                
                                                                                                            </tr>
                                                                                                            <tr v-if="store.trendingisinsList[key].yearly">
                                                                                                                <th class="green">Y</th>
                                                                                                                 <td v-for="list,index in pivotPoints"><span :class="index > 5 ? 'green' : 'red'">{{list.toUpperCase()}}</span>{{getPoints(store.trendingisinsList[key].yearly,list,key)}}</td>
                                                                                                               
                                                                                                            </tr>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td v-if="store.trendingisinsList[key] && store.trendingisinsList[key].news.length ">
                                                                                                    <span class="newsBlockRes" v-for="item,index in store.trendingisinsList[key].news.slice(1,4)" :key="index">
                                                                                                        <span v-if="item.metadata && item.metadata.header">{{item.metadata.header}}</span>
                                                                                                        <span v-else>{{item.title}}</span>
                                                                                                    </span>
                                                                                                </td>
                                                                                                <td v-if="store.trendingisinsList[key] && store.trendingisinsList[key].trade.length">
                                                                                                    <div class="perparing" v-for="item,index in store.trendingisinsList[key].trade.slice(1,4)" :key="index">
                                                                                                        <span class="mb-1 d-block f-12 medium">{{item.title}}</span>
                                                                                                        <!-- <div class="smallbtn intable d-flex align-items-center">
                                                                                                            <a href="javascript:void(0)" class="sharemkt me-1"><img src="assets/images/bull.svg" /></a>
                                                                                                            <a href="javascript:void(0)" class="tagged">Long</a> <a href="javascript:void(0)" class="tagged">Premium</a>
                                                                                                        </div> -->
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="5" class="text-center">
                                            <div class="text-center loadingIcon">
                                                <img src="assets/images/loader.gif" alt="Icon" class="norecord_img">
                                                <p>Loading please wait..</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white space">
            <div class="container">
                <div class="row mb-md-5">
                    <div class="col-12 col-lg-8 mb-4 mb-lg-0">
                        <h5 class="mb-4 medium">What’s Trending</h5>
                        <div class="row">
                            <div class="col-12 col-xl-4 mb-4 mb-xl-0" v-if="store.trendingNews.totalRecords">
                                <div class="card-body economy bglight border trade shadow-none wTrends">
                                    <h6 class="border-bottom pb-2">News</h6>
                                    <img class="my-2" :src="store.trendingNews.records[0].details.image" alt="News"
                                        title="News" v-if="store.trendingNews.records[0].details" />
                                    <div class="content" :id="'news'+store.trendingNews.records[0]._id">
                                        <h6 class="f-14" v-if="store.trendingNews.records[0].details"><a
                                                href="javascript:void(0)"
                                                @click="()=> goToDetailPage(store.trendingNews.records[0].details)">{{store.trendingNews.records[0].details.title}}</a>
                                        </h6>
                                        <ul class="datTime d-flex align-items-center">
                                            <li class="me-4 d-flex align-items-center">
                                                <vue-feather type="folder" size="14px" class="me-1"></vue-feather>
                                                Politics Money
                                            </li>
                                            <li class="d-flex align-items-center"
                                                v-if="store.trendingNews.records[0].details">
                                                <vue-feather type="clock" size="14px" class="me-1"></vue-feather>
                                                {{formatNow(store.trendingNews.records[0].details.published,'now',0)}}
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="inter d-flex align-items-start mb-3"
                                        v-for="item,index in store.trendingNews.records.slice(1,4)">
                                        <!-- <span class="flex-none mb-2 mb-md-0"><img height="60" width="60"
                                                :src="item.details.image" alt="News" title="News" /></span> -->
                                        <div class="flex-grow-1" :id="'news'+item._id">
                                            <h6 class="f-14"><a href="javascript:void(0)"
                                                    @click="()=> goToDetailPage(item.details)">{{item.details.title}}</a>
                                            </h6>
                                            <p class="lighttext d-flex align-items-center mb-0">
                                                <vue-feather type="clock" size="14px" class="me-1"></vue-feather>
                                                {{formatNow(item.details.published,'now',0)}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-xl-8">
                                <ul class="toplinks pb-2 d-flex align-items-center mb-lg-4 mb-2 border-bottom">
                                    <li class="ps-0"><a :class="[{'active': tab == 'Ideas'}]" href="javascript:void(0)"
                                            @click="tab = 'Ideas'"> Ideas</a></li>
                                    <!-- <li><a href="javascript:void(0)" :class= "[{'active': tab == 'chart'}]" @click="tab = 'chart'">Chart Patterns</a></li> -->
                                    <li><a href="javascript:void(0)" :class="[{'active': tab == 'analysis'}]"
                                            @click="tab = 'analysis'">Technical Analysis</a></li>
                                </ul>
                                <div class="showTabsList mob-scroll-content row ideaMarket"
                                    v-if="tab == 'Ideas' && store.tradeIdeasList.totalRecords">
                                    <div class="col-12 col-xl-6 mb-4"
                                        v-for="item,index in store.tradeIdeasList.records.slice(0,4)">
                                        <div class="card border-0 mb-4 midNew h-100" :id="'trade'+item.user_id">
                                            <div class="card-body pointer spaceBetween">
                                                <div class="upText">
                                                    <h4 class="card-title"><a href="javascript:void(0)"
                                                            @click=" () => goToTradeDetail(item)">{{item.title}}</a>
                                                        <span class="modifyDate">{{formatDate(item.created)}}</span>
                                                    </h4>
                                                    <img :src="imageLink+'/get/doc?type=TRADE&doc='+item.document+'&id='+item.user_id"
                                                        class="img-fluid" alt="" />
                                                    <div
                                                        class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                                                        <div class="forexnood d-flex align-items-center" @click="() => goToProfilePage(item.user_id)"><p class="IdeaUsername mb-0">{{item.firstname.charAt(0) || ''}}{{item.lastname.charAt(0) || ''}}</p> <span
                                                                class="mx-2">{{item.firstname || ''}} {{item.lastname ||
                                                                ''}}</span> 
                                                        </div>
                                                        <div class="currency-wrap">
                                                            <span class="currency"><a
                                                                    href="javascript:void(0)">{{item.symbol ||
                                                                    'N/A'}}</a></span> <span class="monthly"><a
                                                                    href="javascript:void(0)">{{item.time_interval ||
                                                                    'N/A'}}</a></span>
                                                            <span class="short">
                                                                <a href="javascript:void(0)"
                                                                    :class="SymbolClass[item.position.toLowerCase()]"><i
                                                                        data-feather="arrow-down-right"></i>
                                                                    {{item.position
                                                                    || 'N/A'}}</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <!-- <p>{{item.description}}</p> -->
                                                </div>
                                                <!-- <div class="downText">
                                                    <div class="smallbtn d-flex align-items-center"><span
                                                            class="cardEelement">{{item.classification || 'N/A'}}</span>
                                                        <span class="cardEelement" @click="type = item.type">{{item.type
                                                        ||
                                                        'N/A'}}</span>
                                                    </div>
                                                    <div
                                                        class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                                                        <div @click="() => likeIdea(item,'like')">
                                                            <span><i :class="parseInt(item.is_like) == 0 ? 'fa fa-heart-o' : 'fa fa-heart'" aria-hidden="true"></i>
                                                                {{item.likes_count || 0}}</span>
                                                        </div>
                                                        <div class="righticons">
                                                            <span @click="() => likeIdea(item,'comment')"><i class="fa fa-comments-o"
                                                                    aria-hidden="true"></i>{{item.comment_count ||
                                                                    0}}</span> <span><i class="fa fa-share-alt"
                                                                    aria-hidden="true"></i> {{item.share_count ||
                                                                    0}}</span>
                                                            <span><i class="fa fa-bookmark-o" aria-hidden="true"></i>
                                                                {{item.bookmarks_count || 0}}</span>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-4 mb-xl-0"
                                    v-if="tab == 'analysis' && store.treandingInsight.totalRecords">
                                    <div class="inter d-flex align-items-start mb-3"
                                        v-for="item,index in store.treandingInsight.records.slice(0,4)">
                                        <span class="flex-none mb-2 mb-md-0"><img height="60" width="60"
                                                :src="item.image_url" alt="News" title="News" /></span>
                                        <div class="flex-grow-1 ms-3" :id="'news'+item._id">
                                            <h6 class="f-14" v-if="item.metadata"><a href="javascript:void(0)"
                                                    @click="()=> goToPage(item)">{{item.metadata.header}}</a></h6>
                                            <p v-html="item.text.substring(0,100)+'...'"></p>
                                            <p class="lighttext d-flex align-items-center mb-0">
                                                <vue-feather type="clock" size="14px" class="me-1"></vue-feather>
                                                {{formatNow(item.created,'now',0)}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="economicAllNews mb-4 mb-lg-5">
                            <div class="d-flex justify-content-between align-items-center mb-4">
                                <h3 class="m-0">Economic Calendar</h3>
                                <router-link to="/economic-calendar" class="green_btn d-flex align-items-center">See All
                                    <vue-feather size="12px" type="chevron-right" class="chevron-right"></vue-feather>
                                </router-link>
                            </div>
                            <div v-if="economicData.length" class="table-responsive economic">
                                <table v-for="(row, indexOuter) of economicData"
                                    class="table table-sm border-0 bg-white" :class="{'border-top': indexOuter > 0}"
                                    :key="row.date">
                                    <thead>
                                        <tr role="button" @click="()=> toggleOuter(indexOuter)">
                                            <th class="date medium">{{COMMON.formatDate(row.date, "Do MMMM")}}</th>
                                            <th colspan="3" class="events text-end">{{row.items.length}}events</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="activeOuter==indexOuter">
                                        <tr role="button" v-for="(event, indexInner) of rowInnerList"
                                            @click="()=> toggleInner(indexInner, event)" class="borderAdd"
                                            :class="{active: activeInner==indexInner, rt: event.importance==1, et: event.importance==2, bt: event.importance==3,}"
                                            :key="event._id">
                                            <td class="p-0 border-0" colspan="4">
                                                <table width="100%" class="fixed-layout nowrap">
                                                    <tr>
                                                        <td :class="{'active-td': activeInner==indexInner}">
                                                            <span class="d-flex align-items-center">
                                                                <img width="25" height="25"
                                                                    :src=" 'assets/images/country_flag/' + event.country.toLowerCase() + '.webp' "
                                                                    class="rounded-circle me-2" alt="" /><span
                                                                    class="ellipsis">{{event.country}}</span>
                                                            </span>
                                                        </td>
                                                        <td>{{COMMON.formatDate(event.date, "hh:mm a")}}</td>
                                                        <td>{{event.event}}</td>
                                                        <td>
                                                            <a class="d-block text-end" href="javascript:void(0);">
                                                                <vue-feather v-if="activeInner !=indexInner"
                                                                    type="chevron-down"></vue-feather>
                                                                <vue-feather v-if="activeInner==indexInner"
                                                                    type="chevron-up"></vue-feather>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr class="subactive-tr" v-if="activeInner==indexInner">
                                                        <td colspan="4">
                                                            <div
                                                                class="d-flex align-items-center justify-content-between openDom">
                                                                <span class="subactive-td">
                                                                    <div class="f-16">{{COMMON.formatCalendarDate(
                                                                    activeEvent.date, "duration")}}</div>
                                                                    <div class="f-12">Time{{COMMON.formatDate(
                                                                    activeEvent.date, "HH:mm")}}</div>
                                                                </span>
                                                                <span align="center"
                                                                    class="active-time-int middle">Actual{{activeEvent.actual
                                                                    ? activeEvent.actual : "-"}}</span>
                                                                <span colspan="2" class="subactive-td2">
                                                                    <div class="forecast"><span
                                                                            class="f-12 me-2">Forecast</span> <span
                                                                            class="f-16">{{activeEvent.teForecast ?
                                                                            activeEvent.teForecast : "-"}}</span></div>
                                                                    <div class="previe"><span
                                                                            class="f-12 me-2">Previous</span> <span
                                                                            class="f-16">{{activeEvent.previous ?
                                                                            activeEvent.previous : "-"}}</span></div>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- <div class="blockAdd">
                            <div class="d-flex justify-content-between align-items-center mb-4">
                                <h5 class="mb-0 medium">Sentiments</h5>
                                <div class="d-flex align-items-center">
                                    <ul class="toplinks d-flex align-items-center mb-0 pb-2">
                                        <li><a href="javascript:void(0)" class="active">All</a></li>
                                        <li><a href="javascript:void(0)">Forex</a></li>
                                        <li><a href="javascript:void(0)">Crypto</a></li>
                                        <li class="noneHover p-0">
                                            <a href="#" class="dropdown-togglen noArrow" data-toggle="dropdown"><i class="textcolor" data-feather="chevron-down"></i></a>
                                            <div class="dropdown-menu p-0"><a class="dropdown-items" href="#">Currencies</a> <a class="dropdown-items" href="#">Indices</a></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="customProgressbar">
                                <ul class="barList position-relative">
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">BCHUSD </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 18%;" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">18%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter1.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 67%;" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100">67%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 33%;" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">33%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter2.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 27%;" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100">27%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 73%;" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100">73%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 77%;" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100">77%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 23%;" aria-valuenow="23" aria-valuemin="0" aria-valuemax="100">23%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter4.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 35%;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">35%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 35%;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">35%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">BCHUSD </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 18%;" aria-valuenow="18" aria-valuemin="0" aria-valuemax="100">18%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter1.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 67%;" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100">67%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 33%;" aria-valuenow="33" aria-valuemin="0" aria-valuemax="100">33%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter2.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 27%;" aria-valuenow="27" aria-valuemin="0" aria-valuemax="100">27%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 73%;" aria-valuenow="73" aria-valuemin="0" aria-valuemax="100">73%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 77%;" aria-valuenow="77" aria-valuemin="0" aria-valuemax="100">77%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 23%;" aria-valuenow="23" aria-valuemin="0" aria-valuemax="100">23%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter4.svg" alt="Icon" /></span>
                                    </li>
                                    <li class="d-flex align-items-center justify-content-between">
                                        <span class="barTitle flex-shrink-0 border-bottom py-1 f-12">DOGEUSDT </span>
                                        <span class="barPro w-100 d-flex align-items-center pe-3">
                                            <div class="progress w-50 flex-row-reverse border-radius-0 h-18">
                                                <div class="progress-bar bargreenBG text-right pr-2" role="progressbar" style="width: 82%;" aria-valuenow="82" aria-valuemin="0" aria-valuemax="100">82%</div>
                                            </div>
                                            <div class="progress w-50 border-radius-0 h-18">
                                                <div class="progress-bar barredBG text-left pl-2" role="progressbar" style="width: 35%;" aria-valuenow="35" aria-valuemin="0" aria-valuemax="100">35%</div>
                                            </div>
                                        </span>
                                        <span class="barMeter flex-shrink-0 line-1"><img src="assets/images/meter3.svg" alt="Icon" /></span>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!--  <div class="row">
                    <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                        <h5 class="mb-4 medium">Tranding Symbols</h5>
                        <ul class="trandingSymbols p-3 d-flex align-items-center flex-wrap">
                            <li class="border-bottom trade">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green">(+147.70)</p>
                                </span>
                            </li>
                            <li class="border-bottom trade">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green">(+147.70)</p>
                                </span>
                            </li>
                            <li class="border-bottom trade">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green">(+147.70)</p>
                                </span>
                            </li>
                            <li class="mb-lg-0 pb-lg-0">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green">(+147.70)</p>
                                </span>
                            </li>
                            <li class="mb-lg-0 pb-lg-0">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green">(+147.70)</p>
                                </span>
                            </li>
                            <li class="mb-lg-0 pb-lg-0">
                                <span class="d-flex align-items-center">
                                    <span class="greenBtn"><i class="fa fa-caret-up" aria-hidden="true"></i></span>
                                    <span class="flex-grow-1 ms-2">
                                        <p class="mb-0 medium">Dow Jones</p>
                                        <p class="mb-0 green">+5.25%</p>
                                    </span>
                                </span>
                                <span class="content ms-2">
                                    <p class="mb-0 medium">434230.34</p>
                                    <p class="mb-0 green">(+147.70)</p>
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-xl-4">
                        <h5 class="mb-4 medium">Tranding Search</h5>
                        <ul class="checkLabel-btn d-flex align-items-center flex-wrap">
                            <li>
                                <label class="customCheckbox" :class="trandingTabs ? 'active' : ''">
                                    <span class="d-flex align-items-center">Chart Patterns<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input @click="trandingTabs=!trandingTabs" type="checkbox" name="" />
                                    <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox">
                                    <span class="d-flex align-items-center">Economic Calendar<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input type="checkbox" name="" /> <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox">
                                    <span class="d-flex align-items-center">Fed Chairman<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input type="checkbox" name="" /> <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox">
                                    <span class="d-flex align-items-center">Bitcoin ban in India<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input type="checkbox" name="" /> <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox">
                                    <span class="d-flex align-items-center">Covid 3rd wave<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input type="checkbox" name="" /> <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox">
                                    <span class="d-flex align-items-center">BOE Stimulus<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input type="checkbox" name="" /> <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox">
                                    <span class="d-flex align-items-center">Chart Patterns<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input type="checkbox" name="" /> <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox">
                                    <span class="d-flex align-items-center">Economic Calendar<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input type="checkbox" name="" /> <span class="checkmark"></span>
                                </label>
                            </li>
                            <li>
                                <label class="customCheckbox">
                                    <span class="d-flex align-items-center">Fed Chairman<i class="ms-1 fa fa-check-circle" aria-hidden="true"></i></span> <input type="checkbox" name="" /> <span class="checkmark"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </section>
        <login v-if="showPopup"></login>
    </div>
</template>


<script>
import { Const } from "@/plugins/vue-provider-cache/const.mod";
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
import login from "../../components/shared/userlogin"
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            trandingTabs: false,
            details: -1,
            currenTab: "Trending",
            activeOuter: 0,
            activeInner: 0,
            imageLink: process.env.VUE_APP_API_BASE_URL,
            SymbolClass: {
                'neutral': 'yellowBG',
                'buy': 'greenBG',
                'sell': 'redBG'
            },
            tab: 'Ideas',
            showPopup: '',
            pivotPoints : ['s5','s4','s3','s2','s1','p','r1','r2','r3','r4','r5'],
            errImage : []
        };
    },
    components: {
        bannerSlider: bannerslider,
        Form,
        Field,
        ErrorMessage,
        login
    },
    methods: {
        isMonday() {
            if (parseInt(moment().day()) == 1) {
                return true;
            } else {
                return false;
            }
        },
        isMonthFirstDay() {
            if (moment().startOf("month").format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                return true;
            } else {
                return false;
            }
        },
        isImage(img){ 
            return (this.errImage.includes(img))?false:true
        },
        handleImgErr(val){
            var fullSrc = val.target.src.split(".")
            var src = fullSrc[fullSrc.length-2].split("/")
            var img = src[src.length - 1]
            if(!this.errImage.includes(img)){
                this.errImage.push(img)
            }
        },
        formatEventDate(date){
            return moment(date).format('MMM Do YYYY | hh:mm a')
        },
        getPoints(symbol, point , key) {
            if(Object.keys(this.store.trendingisinsList).length){
                symbol = JSON.parse(symbol);
                //Pivot value
                if(symbol.high && symbol.low && symbol.close){

                    if(point == 'p'){
                        return  parseFloat((symbol.high + symbol.low + symbol.close) / 3).toFixed(4);
                    }

                  //Resistance values
                  if (point == "r5") {
                    return parseFloat(
                      ((symbol.high - symbol.low) * 1.1) / 1 + symbol.close
                    ).toFixed(4);
                  }
                  if (point == "r4") {
                    return parseFloat(
                      ((symbol.high - symbol.low) * 1.1) / 2 + symbol.close
                    ).toFixed(4);
                  }
                  if (point == "r3") {
                    return parseFloat(
                      ((symbol.high - symbol.low) * 1.1) / 4 + symbol.close
                    ).toFixed(4);
                  }
                  if (point == "r2") {
                    return parseFloat(
                      ((symbol.high - symbol.low) * 1.1) / 6 + symbol.close
                    ).toFixed(4);
                  }
                  if (point == "r1") {
                    return parseFloat(
                      ((symbol.high - symbol.low) * 1.1) / 12 + symbol.close
                    ).toFixed(4);
                  }

                  //Support values
                  if (point == "s1") {
                    return parseFloat(
                      symbol.close - ((symbol.high - symbol.low) * 1.1) / 12
                    ).toFixed(4);
                  }
                  if (point == "s2") {
                    return parseFloat(
                      symbol.close - ((symbol.high - symbol.low) * 1.1) / 6
                    ).toFixed(4);
                  }
                  if (point == "s3") {
                    return parseFloat(
                      symbol.close - ((symbol.high - symbol.low) * 1.1) / 4
                    ).toFixed(4);
                  }
                  if (point == "s4") {
                    return parseFloat(
                      symbol.close - ((symbol.high - symbol.low) * 1.1) / 2
                    ).toFixed(4);
                  }
                  if (point == "s5") {
                    return parseFloat(
                      symbol.close - ((symbol.high - symbol.low) * 1.1) / 1
                    ).toFixed(4);
                  }
              }else{
                return ''
              }
            }else{
                return ''
            }

        },
        isMonday(){
          if(parseInt(moment().day()) == 1){
            return true
          }else{
            return false
          }
        },
        isMonthFirstDay(){
          if(moment().startOf("month").format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")){
            return true
          }else{
            return false
          }
        },
        goToProfilePage(id) {
            if (this.store.user.profile) {
                if (parseInt(this.store.user.profile.user_id) != parseInt(id)) {
                    this.$router.push({
                        name: 'profile',
                        query: { 'id': id }
                    });
                } else {
                    this.$router.push({
                        name: 'profile',
                    });
                }
            } else {
                this.$router.push({
                    name: 'profile',
                    query: { 'id': id }
                });
            }
        },
        likeIdea(item,type) {
            if (Object.keys(this.store.user).length > 0 && this.store.user.profile) {
                this.showPopup = ''
                if(type == 'like'){
                  this.callUpdateTrade(item)
                 }else{
                  this.goToTradeDetail(item)
                 }
            } else {
                this.showPopup = item.trade_id
            }
        },
        callUpdateTrade(item) {
            let form = {
                "type": "TRADE", "is_like": 1, "user_id": this.store.user.profile.user_id, "target_id": item.trade_id
            }
            if(parseInt(item.is_like) == 1){
                form['is_like'] = 0
            }
            if(this.store.tradeIdeasList.totalRecords){
                this.store.tradeIdeasList.records.forEach(val =>{
                  if(parseInt(val.trade_id) == parseInt(item.trade_id)){
                    if(parseInt(val.is_like) == 1){
                      val.is_like = 0
                       val.likes_count = parseInt( val.likes_count)-1
                    }else{
                      val.is_like = 1
                       val.likes_count = parseInt( val.likes_count)+1
                    }
                  }
                })
            }
            this.store.callUpdateIdeas(form, true)
        },
        getMarketInsight() {
            let json = {
                page: 1,
                perPage: 15,
            };
            this.store
                .callTreandingFinancialContent(json, false)
                .then(() => { })
                .catch((err) => console.log(err.message));
        },
        toDivElement(id) {
            try {
                setTimeout(function () {
                    var elmnt = document.getElementById(id);
                    if(elmnt){
                        elmnt.scrollIntoView({ behavior: "smooth" });
                        document.getElementById(id).focus();
                        //var top = $(window).scrollTop()
                        // console.log(id,'top',top)
                        // $(window).scrollTop(top-80)
                    }
                }, 500);
            } catch (e) { }
        },
        goToTradeDetail(item) {
            let title = item.title
            if (title.includes('-')) {
                title = title.replace(/-+/g, '')
            } else {
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if (titl.includes('--')) {
                    title = titl.replace(/--+/g, '-')
                } else {
                    title = item.title
                }
            }
            this.$router.push({
                name: 'details',
                params: { 'id': item.trade_id, 'title': title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'), 'page': 1 }
            });
            /*/ +/g, '-'*/
        },
        goToPage(item) {
            let title = ''
            if (item.source != 'FXS') {
                title = item.metadata.header
            } else {
                title = item.title
            }
            if (title.includes('-')) {
                title = title.replace(/-+/g, '')
            } else {
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if (titl.includes('--')) {
                    title = titl.replace(/--+/g, '-')
                } else {
                    if (item.source != 'FXS') {
                        title = item.metadata.header
                    } else {
                        title = item.title
                    }
                }
            }
            this.$router.push({
                name: 'insight_detail',
                params: { 'id': item._id, 'title': title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'), 'page': 1 }
            });
        },
        goToDetailPage(item) {
            let title = ''
            if(item.source == 'HOOPS'){
                if(item.source != 'FXS'){
                    title = item.metadata.header
                }else{
                    title = item.title
                }
                if(title.includes('-')){
                    title = title.replace(/-+/g, '')
                }else{
                    let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                    if(titl.includes('--')){
                        title = titl.replace(/--+/g, '-')
                    }else{
                        if(item.source != 'FXS'){
                            title = item.metadata.header
                        }else{
                             title = item.title
                        }
                    }
                }
                this.$router.push({
                    name: 'insight_detail',
                    params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : this.page}
                });
            }else{
                if(item.title){
                    title = item.title
                    if (title.includes('-')) {
                    title = title.replace(/-+/g, '')
                    } else {
                        let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                        if (titl.includes('--')) {
                            title = titl.replace(/--+/g, '-')
                        } else {
                            title = item.title
                        }
                    }
                }else{
                    title = item.metadata.header
                    if (title.includes('-')) {
                    title = title.replace(/-+/g, '')
                    } else {
                        let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                        if (titl.includes('--')) {
                            title = titl.replace(/--+/g, '-')
                        } else {
                            title = item.metadata.header
                        }
                    }
                }
                
                this.$router.push({
                    name: 'news_details',
                    params: { 'id': item._id, 'title': title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'), 'page': 1 }
                });
            }
        },
        formatDate(val) {
            return moment(val).format('DD MMM')
        },
        formatNow(date, format, offset) {
            if (!date) return 'N/A'
            let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        movementBar(isin) {
            //console.log(this.instruments)
            // let leftElement = document.getElementById("leftBox");
            // let rightElement = document.getElementById("rightBox");
            // let currentElement = document.getElementById("currentPrice");
            let styleObj = {
                left: "",
                right: "",
                current: "",
            };

            let previousClose = isin.other.closepx,
                current = isin.B,
                high = isin.H,
                low = isin.L;

            (high = current > high ? current : high),
                (low = current < low ? current : low);

            let marginFromEdge = Math.abs(
                ((previousClose - low) / (high - low)) * 100
            );
            let innerWidth = Math.abs(
                ((current - previousClose) / (high - low)) * 100
            );
            styleObj.left = `width: ${marginFromEdge}%;`;
            styleObj.right = `width: ${100 - marginFromEdge}%;`;
            // leftElement.style.width = `${marginFromEdge}%`;
            // rightElement.style.width = `${100 - marginFromEdge}%`;
            if (current > previousClose) {
                let pos = marginFromEdge + innerWidth;
                if (pos >= 100) {
                    // currentElement.style.right = 0;
                    // currentElement.style.left = `inherit`;
                    styleObj.current = `left: inherit;right: 0;`;
                } else {
                    // currentElement.style.left = `${pos}%`;
                    // currentElement.style.right = `inherit`;
                    styleObj.current = `left: ${pos}%;right: inherit;`;
                }
            } else {
                let pos = 100 - (marginFromEdge - innerWidth);
                if (pos >= 100) {
                    // currentElement.style.left = 0;
                    // currentElement.style.right = `inherit`;
                    styleObj.current = `left: 0;right: inherit;`;
                } else {
                    // currentElement.style.right = `${pos}%`;
                    // currentElement.style.left = `inherit`;
                    styleObj.current = `left: inherit;right: ${pos}%;`;
                }
            }
            // if (isin.other.isinid == "USDRON") {
            //     console.log(isin, styleObj);
            // }
            return styleObj;
        },
        weeklymovementBar(isin) { 
            let styleObj = {};
            if(Object.keys(this.store.trendingisinsList).length && isin && this.store.trendingisinsList[isin.other.mn]){
                styleObj = {
                    left: "",
                    right: "",
                    current: ""
                };
                let weeklydata = JSON.parse(this.store.trendingisinsList[isin.other.mn].weekly)
                if(weeklydata.low && weeklydata.high && weeklydata.close && isin.B && isin.H && isin.L && isin.other.closepx){
                    let previousClose = weeklydata.close,
                    current = isin.B,
                    high =  weeklydata.high,
                    low = weeklydata.low;
                    if(parseFloat(weeklydata.high) < parseFloat(isin.H)){
                        //weekly high lesser than today High
                        high = isin.H
                    }
                    if(parseFloat(isin.L) < parseFloat(weeklydata.low)){
                        low = isin.L
                    }
                    if(parseInt(moment().day()) == 1){//Monday
                        low = isin.L
                        high = isin.H 
                    }
                    (high = current > high ? current : high),
                    (low = current < low ? current : low);
                    let marginFromEdge = Math.abs(
                        ((previousClose - low) / (high - low)) * 100
                    );
                    let innerWidth = Math.abs(
                        ((current - previousClose) / (high - low)) * 100
                    );
                    styleObj.left = `width: ${marginFromEdge}%;`;
                    styleObj.right = `width: ${100 - marginFromEdge}%;`;
                    // leftElement.style.width = `${marginFromEdge}%`;
                    // rightElement.style.width = `${100 - marginFromEdge}%`;
                    if (current > previousClose) {
                        let pos = marginFromEdge + innerWidth;
                        if (pos >= 100) {
                            // currentElement.style.right = 0;
                            // currentElement.style.left = `inherit`;
                            styleObj.current = `left: inherit;right: 0;`;
                        } else {
                            // currentElement.style.left = `${pos}%`;
                            // currentElement.style.right = `inherit`;
                            styleObj.current = `left: ${pos}%;right: inherit;`;
                        }
                    } else {
                    let pos = 100 - (marginFromEdge - innerWidth);
                    if (pos >= 100) {
                        // currentElement.style.left = 0;
                        // currentElement.style.right = `inherit`;
                        styleObj.current = `left: 0;right: inherit;`;
                    } else {
                        // currentElement.style.right = `${pos}%`;
                        // currentElement.style.left = `inherit`;
                        styleObj.current = `left: inherit;right: ${pos}%;`;
                    }
                }
                if (isin.other.isinid == "USDRON") {
                    console.log(isin, styleObj);
                }
                return styleObj;
                }else{
                    return styleObj = {
                        left: "",
                        right: "",
                        current: "",
                    };
                }
            }else{
                return styleObj = {
                    left: "",
                    right: "",
                    current: "",
                };
            }
        },
        getTrendingData(isins, type, current) {
            if (isins && this.store.trendingisinsList[isins]) {
                if (this.store.trendingisinsList[isins][type]) {
                    let data = JSON.parse(this.store.trendingisinsList[isins][type]);
                    //console.log(data.close)
                    return this.COMMON.formatPrice(
                        // ((data.close - current) / current) * 100
                        ((current-data.open)/data.open) * 100
                    ); 
                } else {
                    return '-'
                }
            }
        },
        getTrendingNews() {
            let formData = {
                page: 1,
                perPage: 10,
            };
            this.store.callTrendingNews(formData, false).then(() => {
            }).catch(err => console.log(err.message))
        },
         getTrendingSymbolEvents(key) {
            this.details = key
            let formData = {
                'symbol' : key
            };
            this.store.callTrendingSymbolEvents(formData, false).then(() => {
            }).catch()
        },
        subscribe() {
            try{
                let id = this.getCookie('subscribeId')
                if(id){
                    let subscribeArray = Object.keys(this.store.trendingisinsList);
                    this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].seeders[
                        "S_PF_PF"
                      ].subscribe(subscribeArray,id);
                }
            }catch(e){};
        },
        unSubscribe() {
          /*let subscribeArray = [];
          this.combinedCurrency.forEach((item, index) => {
            subscribeArray.push(item);
          });
          try{
            this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].seeders["S_PF_PF"].unsubscribe(subscribeArray);
          }catch(e){}*/
        },
        getTrendingIsinsList(){
            this.store.callTrendingIsinsList({
            }, true).then(() => {
                if(Object.keys(this.store.trendingisinsList).length){
                    this.subscribe()
                }
            }).catch()
        },
        getTradeIdea() {
            let formData = {
                page: 1,
                perPage: 8,
                "filter": { "trade_ideas.type": "Trade Idea" },
                "sort": { "field": "trade_ideas.view_count", "type": "DESC" }
            };
            if(this.store.user.profile){
                formData['logged_user_id'] = this.store.user.profile.user_id
              }
            this.store
                .callTradeIdeasList(formData, false)
                .then((response) => {
                })
                .catch();
        },
        getCalendar() {
            let formData = {
                page: 1,
                perPage: 15,
            };
            this.store
                .getCalendar(formData, false)
                .then(() => { })
                .catch();
        },
        toggleOuter(index) {
            if (this.activeOuter == index) {
                this.activeOuter = -1;
                this.activeInner = -1;
            } else {
                this.activeOuter = index;
                this.activeInner = -1;
            }
        },
        toggleInner(index, event) {
            if (this.activeInner == index) {
                this.activeInner = -1;
                //this.activeEvent = {};
            } else {
                this.activeInner = index;
                //this.activeEvent = event;
            }
        },
        setCookie:(cname,cvalue,exdays)=>{
          const d = new Date();
          d.setTime(d.getTime() + (exdays*24*60*60*1000));
          let expires = "expires=" + ((cvalue)?d.toUTCString():new Date());
          document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=.finvasia.com";
        },
        getCookie:(cname)=>{
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
    },
    computed: {
        weekprogressChart() {
            if (Object.keys(this.store.trendingisinsList).length && this.details && this.store.trendingisinsList[this.details]) {
                let weeklydata = JSON.parse(this.store.trendingisinsList[this.details].weekly);
                if (Object.keys(weeklydata).length) {
                    if (weeklydata.low && weeklydata.high  && this.instruments[this.details] && this.instruments[this.details].B && this.instruments[this.details].H && this.instruments[this.details].L) {
                        let width = 0,
                            margin = 0;
                        if (this.instruments[this.details].B > weeklydata.high) {
                            //cuurent value greater than high
                            weeklydata.high = this.instruments[this.details].B;
                        } else if (this.instruments[this.details].B < weeklydata.low) {
                            //cuurent value lesser than low
                            weeklydata.low = this.instruments[this.details].B;
                        }
                        if (parseFloat(weeklydata.high) < parseFloat(this.instruments[this.details].H)) {
                            //weekly high lesser than today High
                            weeklydata.high = this.instruments[this.details].H;
                        }
                        if (parseFloat(this.instruments[this.details].L) < parseFloat(weeklydata.low)) {
                              //today high lesser than weekly High
                            weeklydata.low = this.instruments[this.details].L;
                        }
                        if (weeklydata.open >= parseFloat(this.instruments[this.details].B)) {
                            //current < previous close
                            if (parseFloat(this.instruments[this.details].B) > 0) {
                                //current value positive
                                width = ((weeklydata.open - parseFloat(this.instruments[this.details].B)) / (weeklydata.high - weeklydata.low)) * 100;
                                margin = ((weeklydata.open - weeklydata.low) / (weeklydata.high - weeklydata.low)) * 100;
                                margin = 100 - margin;
                                if (parseInt(moment().day()) == 1) {
                                    //Monday
                                    weeklydata.low = this.instruments[this.details].L;
                                    weeklydata.high = this.instruments[this.details].H;
                                }
                                // let percentLow = parseFloat(weeklydata.low) + (parseFloat((weeklydata.low)*0.5/100))
                                // let percentHigh = parseFloat(weeklydata.high) - (parseFloat((weeklydata.high)*0.5/100))
                                if (parseFloat(this.instruments[this.details].B) == parseFloat(weeklydata.low)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: true,
                                        high: false,
                                    }; //right :${100 - (margin - width)}%
                                }
                                if (parseFloat(this.instruments[this.details].B) == parseFloat(weeklydata.high)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: false,
                                        high: true,
                                    }; //right :${100 - (margin - width)}%
                                }
                                if (!(parseFloat(this.instruments[this.details].B) == parseFloat(weeklydata.low)) && !(parseFloat(this.instruments[this.details].B) == parseFloat(weeklydata.high))) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: false,
                                        high: false,
                                    }; //right :${100 - (margin - width)}%
                                }
                            }
                        } else {
                            width = ((parseFloat(this.instruments[this.details].B) - weeklydata.open) / (weeklydata.high - weeklydata.low)) * 100;
                            margin = ((weeklydata.open - weeklydata.low) / (weeklydata.high - weeklydata.low)) * 100;
                            if (parseInt(moment().day()) == 1) {
                                //Monday
                                weeklydata.low = this.instruments[this.details].L;
                                weeklydata.high = this.instruments[this.details].H;
                            }
                            // let percentLow = parseFloat(weeklydata.low) + (parseFloat((weeklydata.low)*0.5/100))
                            // let percentHigh = parseFloat(weeklydata.high) - (parseFloat((weeklydata.high)*0.5/100))
                            if (parseFloat(this.instruments[this.details].B) == parseFloat(weeklydata.low)) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: true,
                                    high: false,
                                };
                            }
                            if (parseFloat(this.instruments[this.details].B) == parseFloat(weeklydata.high)) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: false,
                                    high: true,
                                };
                            }
                            if (!(parseFloat(this.instruments[this.details].B) == parseFloat(weeklydata.low)) && !(parseFloat(this.instruments[this.details].B) == parseFloat(weeklydata.high))) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: false,
                                    high: false,
                                };
                            }
                        }
                    } else {
                        return { progressBar: "", icon: "", class: "", low: false, high: false };
                    }
                } else {
                    return { progressBar: "", icon: "", class: "", low: false, high: false };
                }
            } else {
                return { progressBar: "", icon: "", class: "", low: false, high: false };
            }
        },
        monthprogressChart() {
            if (Object.keys(this.store.trendingisinsList).length && this.details && this.store.trendingisinsList[this.details]) {
                let monthlydata = JSON.parse(this.store.trendingisinsList[this.details].monthly);
                if (Object.keys(monthlydata).length) {
                    if (monthlydata.low && monthlydata.high  && this.instruments[this.details] && this.instruments[this.details].B && this.instruments[this.details].H && this.instruments[this.details].L) {
                        let width = 0,
                            margin = 0;
                        if (this.instruments[this.details].B > monthlydata.high) {
                            //cuurent value greater than high
                            monthlydata.high = this.instruments[this.details].B;
                        } else if (this.instruments[this.details].B < monthlydata.low) {
                            //cuurent value lesser than low
                            monthlydata.low = this.instruments[this.details].B;
                        }
                        if (parseFloat(monthlydata.high) < parseFloat(this.instruments[this.details].H)) {
                            //weekly high lesser than today High
                            monthlydata.high = this.instruments[this.details].H;
                        }
                        if (parseFloat(this.instruments[this.details].L) < parseFloat(monthlydata.low)) {
                            monthlydata.low = this.instruments[this.details].L;
                        }
                        if (monthlydata.open >= parseFloat(this.instruments[this.details].B)) {
                            //current < previous close
                            if (parseFloat(this.instruments[this.details].B) > 0) {
                                //current value positive
                                width = ((monthlydata.open - parseFloat(this.instruments[this.details].B)) / (monthlydata.high - monthlydata.low)) * 100;
                                margin = ((monthlydata.open - monthlydata.low) / (monthlydata.high - monthlydata.low)) * 100;
                                margin = 100 - margin;
                                if (moment().startOf("month").format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                                    monthlydata.low = this.instruments[this.details].L;
                                    monthlydata.high = this.instruments[this.details].H;
                                }
                                // let percentLow = parseFloat(monthlydata.low) + (parseFloat((monthlydata.low)*0.5/100))
                                // let percentHigh = parseFloat(monthlydata.high) - (parseFloat((monthlydata.high)*0.5/100))
                                if (parseFloat(this.instruments[this.details].B) == parseFloat(monthlydata.low)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: true,
                                        high: false,
                                    }; //right :${100 - (margin - width)}%
                                }
                                if (parseFloat(this.instruments[this.details].B) == parseFloat(monthlydata.high)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: false,
                                        high: true,
                                    }; //right :${100 - (margin - width)}%
                                }
                                if (!(parseFloat(this.instruments[this.details].B) == parseFloat(monthlydata.low)) && !(parseFloat(this.instruments[this.details].B) == parseFloat(monthlydata.high))) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: false,
                                        high: false,
                                    }; //right :${100 - (margin - width)}%
                                }
                            }
                        } else {
                            width = ((parseFloat(this.instruments[this.details].B) - monthlydata.open) / (monthlydata.high - monthlydata.low)) * 100;
                            margin = ((monthlydata.open - monthlydata.low) / (monthlydata.high - monthlydata.low)) * 100;
                            if (moment().startOf("month").format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                                monthlydata.low = this.instruments[this.details].L;
                                monthlydata.high = this.instruments[this.details].H;
                            }
                            // let percentLow = parseFloat(monthlydata.low) + (parseFloat((monthlydata.low)*0.5/100))
                            // let percentHigh = parseFloat(monthlydata.high) - (parseFloat((monthlydata.high)*0.5/100))
                            if (parseFloat(this.instruments[this.details].B) == parseFloat(monthlydata.low)) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: true,
                                    high: false,
                                };
                            }
                            if (parseFloat(this.instruments[this.details].B) == parseFloat(monthlydata.high)) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: false,
                                    high: true,
                                };
                            }
                            if (!(parseFloat(this.instruments[this.details].B) == parseFloat(monthlydata.low)) && !(parseFloat(this.instruments[this.details].B) == parseFloat(monthlydata.high))) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: false,
                                    high: false,
                                };
                            }
                        }
                    } else {
                        return { progressBar: "", icon: "", class: "", low: false, high: false };
                    }
                } else {
                    return { progressBar: "", icon: "", class: "", low: false, high: false };
                }
            } else {
                return { progressBar: "", icon: "", class: "", low: false, high: false };
            }
        },
        yearprogressChart() {
            if (Object.keys(this.store.trendingisinsList).length && this.details && this.store.trendingisinsList[this.details]) {
                let yearlydata = JSON.parse(this.store.trendingisinsList[this.details].yearly);
                if (Object.keys(yearlydata).length) {
                    if (yearlydata.low && yearlydata.high && yearlydata.open && this.instruments[this.details] && this.instruments[this.details].B && this.instruments[this.details].H && this.instruments[this.details].L) {
                        let width = 0,
                            margin = 0;
                        if (this.instruments[this.details].B > yearlydata.high) {
                            //cuurent value greater than high
                            yearlydata.high = this.instruments[this.details].B;
                        } else if (this.instruments[this.details].B < yearlydata.low) {
                            //cuurent value lesser than low
                            yearlydata.low = this.instruments[this.details].B;
                        }
                        if (parseFloat(yearlydata.high) < parseFloat(this.instruments[this.details].H)) {
                            //yeaerly high lesser than today High
                            yearlydata.high = this.instruments[this.details].H;
                        }

                        if (parseFloat(this.instruments[this.details].L) < parseFloat(yearlydata.low)) {
                            yearlydata.low = this.instruments[this.details].L;
                        }
                        if (yearlydata.open >= parseFloat(this.instruments[this.details].B)) {
                            //current < previous close
                            if (parseFloat(this.instruments[this.details].B) > 0) {
                                //current value positive
                                width = ((yearlydata.open - parseFloat(this.instruments[this.details].B)) / (yearlydata.high - yearlydata.low)) * 100;
                                margin = ((yearlydata.open - yearlydata.low) / (yearlydata.high - yearlydata.low)) * 100;
                                margin = 100 - margin;
                                // let percentLow = parseFloat(yearlydata.low) + (parseFloat((yearlydata.low)*0.5/100))
                                // let percentHigh = parseFloat(yearlydata.high) - (parseFloat((yearlydata.high)*0.5/100))
                                if (parseFloat(this.instruments[this.details].B) == parseFloat(yearlydata.low)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,   #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: true,
                                        high: false,
                                    }; //right :${100 - (margin - width)}%
                                }
                                if (parseFloat(this.instruments[this.details].B) == parseFloat(yearlydata.high)) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,   #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: false,
                                        high: true,
                                    }; //right :${100 - (margin - width)}%
                                }
                                if (!(parseFloat(this.instruments[this.details].B) == parseFloat(yearlydata.low)) && !(parseFloat(this.instruments[this.details].B) == parseFloat(yearlydata.high))) {
                                    return {
                                        progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;right:${parseFloat(margin).toFixed(
                                            4
                                        )}%;position: absolute; background: linear-gradient(to right,  #d04f21 0%, #f7931a 100%);`,
                                        icon: `position: absolute;left:-5px;color:orange`,
                                        class: `Orange`,
                                        low: false,
                                        high: false,
                                    }; //right :${100 - (margin - width)}%
                                }
                            }
                        } else {
                            width = ((parseFloat(this.instruments[this.details].B) - yearlydata.open) / (yearlydata.high - yearlydata.low)) * 100;
                            margin = ((yearlydata.open - yearlydata.low) / (yearlydata.high - yearlydata.low)) * 100;
                            if (parseFloat(this.instruments[this.details].B) == parseFloat(yearlydata.low)) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: true,
                                    high: false,
                                };
                            }
                            if (parseFloat(this.instruments[this.details].B) == parseFloat(yearlydata.high)) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: false,
                                    high: true,
                                };
                            }
                            if (!(parseFloat(this.instruments[this.details].B) == parseFloat(yearlydata.low)) && !(parseFloat(this.instruments[this.details].B) == parseFloat(yearlydata.high))) {
                                return {
                                    progressBar: `width:${parseFloat(Math.abs(width)).toFixed(4)}%;height: 5px;left:${parseFloat(margin).toFixed(4)}%;position: absolute; background: linear-gradient(90deg, #38EF7D 0%, #11998E 100%);`,
                                    icon: `position: absolute;right:-5px;color:green`,
                                    class: `Green`,
                                    low: false,
                                    high: false,
                                };
                            }
                        }
                    } else {
                        return { progressBar: "", icon: "", class: "", low: false, high: false };
                    }
                } else {
                    return { progressBar: "", icon: "", class: "", low: false, high: false };
                }
            } else {
                return { progressBar: "", icon: "", class: "", low: false, high: false };
            }
        },
        dateAdjustedEvents() {
            if (Object.keys(this.store.calendarData).length) {
                let temp = [],
                    allEvents = [];
                for (let key in this.store.calendarData) {
                    this.store.calendarData[key].items.map((i) => {
                        let obj = Object.assign({}, i);
                        obj.date = this.COMMON.formatCalendarDate(
                            obj.date,
                            "YYYY-MM-DD HH:mm",
                            0
                        );
                        //console.log(obj);
                        temp.push(obj);
                    });
                }
                temp.map((i, index) => {
                    const ind = allEvents.findIndex(
                        (j) =>
                            moment(j.date).format("YYYY-MM-DD") ==
                            moment(i.date).format("YYYY-MM-DD")
                    );
                    if (ind > -1) {
                        if (allEvents[ind].items) {
                            allEvents[ind].items.push(i);
                        } else {
                            allEvents[ind].items = [i];
                        }
                    } else {
                        allEvents.push({
                            date: moment(i.date).format("YYYY-MM-DD"),
                            items: [i],
                        });
                    }
                });
                return allEvents;
            } else {
                return [];
            }
        },
        economicData() {
            if (this.dateAdjustedEvents.length) {
                let arr = [];
                this.dateAdjustedEvents.map((i) => {
                    let temp = {
                        date: i.date,
                        items: [],
                    };
                    if (i.items.length) {
                        temp.items = i.items.filter((j) => {
                            return (
                                moment(
                                    this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                                ).diff(moment(), "minute") > 5
                            );
                        });
                    }
                    if (temp.items.length) arr.push(temp);
                });
                return arr.slice(0, 5);
                // return arr;
            } else {
                return [];
            }
        },
        rowInnerList() {
            if (
                this.activeOuter > -1 &&
                this.economicData[this.activeOuter].items &&
                this.economicData[this.activeOuter].items.length
            ) {
                let arr = [];

                arr = this.economicData[this.activeOuter].items.filter((j) => {
                    return (
                        j.importance > 1 &&
                        moment(
                            this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                        ).diff(moment(), "minute") > 5
                    );
                });
                return arr.slice(0, 5);
            } else {
                return [];
            }
        },
        activeEvent() {
            if (this.activeOuter > -1 && this.activeInner > -1)
                return this.economicData[this.activeOuter].items[this.activeInner];
        },
        trendingIsins() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS][
                    "priceFeed"
                ].iCache.getTrendingIsins();
            } catch (ex) {
                return {};
            }
        },
        allTrends() {
            try {
                let data = this.trendingIsins;
                if (Object.keys(data).length > 0) {
                    if (this.currenTab == "Cross") {
                        return Object.values(data).filter((val) =>
                            this.static_vars.crossPairs.includes(val.other.mn)
                        );
                    } else {
                        return Object.values(data)
                    }
                }
            } catch (ex) {
                return {};
            }
        },
        instruments() {
            try {
                this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
                return this.$store.getters[Const.GET_PROVIDERS][
                    "priceFeed"
                ].iCache.asKeysValueArrayAll();
            } catch (ex) {
                return [];
            }
        },
    },
    mounted() {
        if (this.$router.options.history.state.forward && this.$router.options.history.state.forward.split('/')[1] == 'news-detail') {
            let data = this.$router.options.history.state.forward.split('/');
            this.toDivElement('news' + data[2])
            this.$router.options.history.state.forward = ""
            this.page = 1
        }
    },
    created() {
        this.setCookie('subscribeId','1',14)
        this.getCalendar();
        this.getTrendingNews()
        this.getTradeIdea()
        this.getMarketInsight()
        this.getTrendingIsinsList()
    },
};
</script>
<style>
.movementBarContainer {
    width: 100%;
    height: 5px;
    background-color: #eee;
    position: relative;
    display: flex;
}

.leftBox {
    background: linear-gradient(90deg, #F37E20 0%, #F7931A 0.01%, #D04F21 100%);
}

.rightBox {
    background: linear-gradient(90deg, #11998E 0%, #38EF7D 100%);
}

.currentPrice {
    position: absolute;
    top: -7px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #943c3c;
}

.modal.fade.show {
    opacity: 1;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100%;
    overflow: auto;
}
.progress.custom .gdr.fix{bottom:-27px}
.timetitle{position: relative;
    bottom:-18px;}
</style>
